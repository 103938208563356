import axios from "axios";

import Vue from "vue";
import store from "../../store/index";
import { languageTranslation } from "../util/language";
let ctx = null;
export const setCtx = (instance) => {
    ctx = instance;
};
export const baseURL = "https://ev.sjtu.edu.cn/api";
// export const baseURL = "http://127.0.0.1:6789/api";
export const httpURL = "https://ev.sjtu.edu.cn/api";
const $http = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    responseType: "json",
    method: "post",
    headers: {
        "Content-Type": "application/json",
    },
});
$http.interceptors.request.use(
    (config) => {
        //本地存储中的token
        // if (!store.state.user) {
        //     return config;
        // }
        if (store.state.admin) {
            config.headers.Authorization = store.state.admin._id;
            // config.headers.Authorization = '5e38cb5f0663ef7be6bb4703';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
$http.interceptors.response.use(
    (resp) => {
        if (resp.status >= 200 && resp.status < 300) {
            let data = resp.data;
            if (data.code == 0) {
                if (typeof data.data === "string") {
                    return data.data.replace(
                        /www\.yibaicdn\.com/g,
                        "cdn.sjtuair.com"
                    );
                } else {
                    return JSON.parse(
                        JSON.stringify(data.data).replace(
                            /www\.yibaicdn\.com/g,
                            "cdn.sjtuair.com"
                        )
                    );
                }
                // return data.data;
            } else {
                ctx.$Message.error(data.error);

                return Promise.reject(data.error);
            }
        } else {
            return Promise.reject(`${languageTranslation("返回值不是")}200！`);
        }
    },
    (error) => {
        ctx.$Message.error(data.error);
        return Promise.reject(`${languageTranslation("请求异常")}！`);
    }
);

export default $http;
export class HttpUtil {
    static get(url, params = {}) {
        return $http.get(url, {
            params,
        });
    }

    static post(url, body) {
        return $http.post(url, body);
    }

    static put(url, body) {
        return $http.put(url, body);
    }

    static delete(url, params) {
        return $http({
            url,
            params,
            method: "delete",
        });
    }
}

export function initRequestAuthorization(uid) {
    axios.defaults.headers.common["Authorization"] = uid;
}
