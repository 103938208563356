import {
    mapState
} from 'vuex';
import {
    languageTranslation
} from '../assets/util/language';
export const AdminMixins = {
    data() {
        return {

        };
    },
    computed: {
        ...mapState(["admin", 'language'])

    },
    methods: {
        translateLang(key) {
            return languageTranslation(key);
        }
    },
}