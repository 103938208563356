import Vue from "vue";
import { DayUtil } from "./day";

import { languageTranslation } from "./language";
Vue.filter("language", key => {
    return languageTranslation(key);
});
Vue.filter("fmtDate", key => {
    return DayUtil.fmtDate(key);
});
Vue.filter("fmt_time", key => {
    return DayUtil.fmtTime(key);
});
//富文本去除css
Vue.filter("htmlFilter", key => {
    return key
        .replace(/<[^>]+>/g, "")
        .replace(/\&nbsp;/g, "")
        .replace(/\s+/g, "");
});
// 日期分隔符`-`换成`.`
Vue.filter("dotDateFormat", date => {
    return (date+'').split("-").join(".");
});
Vue.filter("userTypeFilter", val => {
    switch (val) {
        case "visitor":
            return "访客";
        case "teacher":
            return "教师";
        case "student":
            return "学生";
        default:
            return "未知";
    }
});
