import $http from "./http.js";


export let eleLanguageMap = null;

if (localStorage.eleLanguageMap) {
    try {
        eleLanguageMap = JSON.parse(localStorage.eleLanguageMap);
    } catch (e) {}
}

export let languageTranslation = (key) => {
    let lang = localStorage.getItem("set_ele_language_en");
    if (lang && JSON.parse(lang) === "en") {
        // dayjs().locale("en");
        return eleLanguageMap ? eleLanguageMap[key] || key : "";
    } else {
        // dayjs().locale("zh");
        return key;
    }
};
export function fetchLanguage() {
    $http
        .post("/language/translation/function", {
            type: 1,
        })
        .then((resObj) => {
            eleLanguageMap = resObj;
            localStorage.eleLanguageMap = JSON.stringify(eleLanguageMap);
        });
}
