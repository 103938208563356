import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import iView from "iview";

import "iview/dist/styles/iview.css"; // 使用 CSS
import "video.js/dist/video-js.css";
import "optiscroll/dist/optiscroll.css";
//拦截器
import $http from "./assets/util/http";

//过滤器
import "../src/assets/util/filter";
import animated from "animate.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import { AdminMixins } from "../src/mixin/admin";
import { Calendar, Card, List, Avatar } from "ant-design-vue";
import { imageIsExist } from "../src/assets/util/defaultImg";

Vue.component(Calendar.name, Calendar);
Vue.component(Card.name, Card);
Vue.component(Avatar.name, Avatar);
Card.install(Vue);
List.install(Vue);
Vue.mixin(AdminMixins);

//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive("real-img", async (el, binding) => {
    let imgURL = binding.value; //获取图片地址
    //设置默认src
    el.setAttribute(
        "src",
        "http://cdn.sjtuair.com/e0221510-48b2-11ea-9f2d-ad4de490c93e.svg"
    );
    if (imgURL) {
        let exist = await imageIsExist(imgURL);

        if (exist) {
            el.setAttribute("src", imgURL);
        } else {
            el.setAttribute(
                "src",
                "http://cdn.sjtuair.com/e0221510-48b2-11ea-9f2d-ad4de490c93e.svg"
            );
        }
    }
});

// Vue.prototype.$slimImg = (src, width = 128) => `${src}?imageView/0/w/${width}`;
Vue.use(VueAwesomeSwiper);
Vue.use(iView);
Vue.use(animated);

Vue.prototype.$http = $http;
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
