import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
dayjs.extend(relativeTime);

export class DayUtil {
    static fmtTime(time) {
        if (!time) return "";
        return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    }
    static fmtDate(time) {
        if (!time) return "";
        return dayjs(time).format("YYYY-MM-DD");
    }
    static fmtMounth(time) {
        if (!time) return "";
        return dayjs(time).format("YYYY.MM");
    }
    static fmtDay(time) {
        if (!time) return "";
        return dayjs(time).format("DD");
    }
    static passedDays(time) {
        if (!time) return "";
        const temp = dayjs(time);
        const current = dayjs();
        return current.from(temp, true);
    }
}
