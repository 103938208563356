<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { fetchLanguage, eleLanguageMap } from "../src/assets/util/language";
import { setCtx } from "../src/assets/util/http";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import zh from "dayjs/locale/zh-cn";
export default {
    beforeCreate() {
        let mutation = [
            { key: "set_ele_language_en", value: "zh" },
            { key: "set_admin", value: null }
        ];
        dayjs.locale("zh-cn");
        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                let d = localStorage.getItem(key);

                let index = mutation.findIndex(item => item.key === key);
                if (index >= 0) {
                    try {
                        d = JSON.parse(d);
                        if (d) {
                            this.$store.commit(key, d);
                        } else {
                            this.$store.commit(key, mutation[index].value);
                        }
                    } catch (error) { }
                }
            }
        }
    },
    created() {
        setCtx(this);
    },
    mounted() {
        fetchLanguage();
    }
};
</script>
<style lang="less">
@import url("./assets/css/common.less");

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;
}

* {
    margin: 0;
    padding: 0;
}

.fix-flex {
    flex: 1;
}

.video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 46px;
    cursor: pointer;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}
</style>
