//在忽略清单里的，或者含有“_ig”的提交则不处理
const IGNORE_lIST = [
    "practise/init_practise",
    "mockExam/init_mockExam",
    "MEIQIA_AUTO_INVITE_REJECTED",
    "logout"
]; //需要加上命名空间
export const syncStore = store => {
    store.subscribe((mutation, state) => {
        if (
            IGNORE_lIST.findIndex(e => e == mutation.type) > -1 ||
            mutation.type.indexOf("_ig") > -1
        ) {
            return;
        }
        // 有参数的时候才进行保存
        if (mutation.payload !== undefined && mutation.payload !== null) {
            localStorage.setItem(
                mutation.type,
                JSON.stringify(mutation.payload)
            );
        }
    });
};