import Vue from "vue";
import Vuex from "vuex";
import { syncStore } from "./plugin";
import { $http, HttpUtil } from "../assets/util/http.js";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import zh from "dayjs/locale/zh-cn";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        language: "zh",
        device_type: [],
        defaultFace:
            "http://cdn.sjtuair.com/9000e310-aaf8-11ea-92ce-0ba4395c428f.svg",
        admin: null,
        emojis: [],
        postList: [],
        FindFriend: {
            name: "寻找队友",
            key: "FindFriend",
            icon: "http://cdn.sjtuair.com/debabad0-a3e1-11ea-9c7a-3be21ca4ba48.svg",
            desc: "",
            list: [],
            count: 0,
            pageNum: 0,
        },
        Interested: {
            name: "课题/项目",
            key: "Interested",
            icon: "http://cdn.sjtuair.com/4400b400-a3e0-11ea-9c7a-3be21ca4ba48.svg",
            desc: "",
            list: [],
            count: 0,
            pageNum: 0,
        },
        FeedBack: {
            name: "优化建议",
            key: "FeedBack",
            icon: "http://cdn.sjtuair.com/40aca700-a3e0-11ea-9c7a-3be21ca4ba48.svg",
            desc: "",
            list: [],
            count: 0,
            pageNum: 0,
        },
        typeList: [],
    },
    getters: {
        typeList: (state) => {
            return [state.FindFriend, state.Interested, state.FeedBack];
        },
    },
    mutations: {
        set_admin(state, arg) {
            state.admin = arg;
        },
        set_ele_language_en(state, val) {
            console.log("------------>>>>>set_ele_language_en");
            if (val == "zh") {
                dayjs.locale("zh-cn");
            } else {
                dayjs.locale("en");
            }
            state.language = val;
        },
        set_device_type(state, val) {
            state.device_type = val;
        },
        set_emojis_ig(state, val) {
            state.emojis = val;
        },
        set_postList(state, val) {
            state.postList = val;
            let type = val?.data?.cond?.type;
            if (type) {
                state[type].list = val.res.result;
                state[type].count = val.res.count;
            }
        },
    },
    actions: {
        async getEmojis({ commit, state }) {
            if (state.emojis && state.emojis.length) {
                return state.emojis;
            }
            const res = await HttpUtil.get("/user/emoji");
            let emojis = res.emojis.map((it) => it.char).slice(0, 147);
            commit("set_emojis_ig", emojis);
            return emojis;
        },
        async getPostList({ commit, state }, data) {
            const res = await HttpUtil.post("/post/list", data);
            commit("set_postList", { res, data });
            return res;
        },
        async jaccLogin({ commit }, arg) {
            let res = await HttpUtil.get(`/user/info/${arg}`);
            commit("set_admin", res);
        },
        async logOut({ commit }, arg) {
            commit("set_admin", null);
            localStorage.setItem("set_admin", null);
        },
        async loginState({ commit }, arg) {
            try {
                commit("set_admin", arg);
            } catch (error) {
                console.log(error);
            }
        },
        async refetchLoginState({ commit }, adminId) {
            if (!adminId) return;
            let res = await HttpUtil.get(`/user/info/${adminId}`);
            if (res) {
                commit("set_admin", res);
            }
        },
    },
    plugins: [syncStore],
    modules: {},
});
