import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const cond = {
    base: "/",
};
const routes = [
    {
        path: "/",
        name: "index",
        component: () =>
            import(/* webpackChunkName: "index" */ "../views/Index.vue"),
    },
    {
        path: "/autologin/:id",
        name: "jaccount",
        component: () =>
            import(
                /* webpackChunkName: "jaccount" */
                "../compontents/index/JaccountLogin.vue"
            ),
    },
    /** 中心简介 */
    {
        path: "/introduction",
        name: "introduction",
        component: () =>
            import(
                /* webpackChunkName: "introduction" */
                "../views/information/Introduction.vue"
            ),
    },
    {
        path: "/videos",
        name: "videos",
        component: () =>
            import(
                /* webpackChunkName: "videos" */
                "../views/videos/index.vue"
            ),
        children: [
            {
                path: "",
                name: "", //默认第一个路由不能添加名称
                component: () =>
                    import(
                        /* webpackChunkName: "videos" */
                        "../views/videos/videos.vue"
                    ),
            },
            {
                path: "more/coCourse",
                name: "more-coCourse",
                component: () =>
                    import(
                        /* webpackChunkName: "more-videos" */
                        "../views/videos/MoreCoCourse.vue"
                    ),
            },
            {
                path: "more/videoCourse",
                name: "more-videoCourse",
                component: () =>
                    import(
                        /* webpackChunkName: "more-videos" */
                        "../views/videos/MoreVideoCourse.vue"
                    ),
            },
            {
                path: "search",
                name: "search-result",
                component: () =>
                    import(
                        /* webpackChunkName: "search-result" */
                        "../views/videos/VideosSearch.vue"
                    ),
            },
            {
                path: ":type/:id",
                name: "video-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "video-detial" */
                        "../views/videos/detail.vue"
                    ),
            },
        ],
    },
    // 党政动态
    // {
    //     path: "/gov",
    //     name: "gov",
    //     component: () =>
    //         import(/* webpackChunkName: "base" */ "../views/Base.vue"),
    //     children: [
    //         {
    //             path: "news",
    //             name: "govnews",
    //             component: () =>
    //                 import(
    //                     /* webpackChunkName: "govnews" */
    //                     "../views/govnews/GovNews.vue"
    //                 ),
    //         },
    //         //党政动态详情
    //         {
    //             path: "news/:id",
    //             name: "govnews-detail1",
    //             component: () =>
    //                 import(
    //                     /* webpackChunkName: "govnews-detail1" */
    //                     "../views/govnews/GovNewsDetail.vue"
    //                 ),
    //             props: {
    //                 url: "/govnews/news",
    //             },
    //         },
    //     ],
    // },
    {
        path: "/base",
        name: "base",
        component: () =>
            import(/* webpackChunkName: "base" */ "../views/Base.vue"),
        children: [
            /** 信息发布 */
            //竞赛活动轮播
            {
                path: "information/competition-activities-index",
                name: "competition-activities-index",
                component: () =>
                    import(
                        /* webpackChunkName: "competition-activities-carousel" */
                        "../compontents/information/ActiveCarousel.vue"
                    ),
            },
            //竞赛活动
            {
                path: "information/competition-activities",
                name: "competition-activities",
                component: () =>
                    import(
                        /* webpackChunkName: "competition-activities" */
                        "../views/information/CompetitionActivities.vue"
                    ),
            },
            //竞赛活动详情
            {
                path: "information/competition-activities/detail/:id",
                name: "competition-activities-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "competition-activities-detail" */
                        "../views/information/CompetitionDetail.vue"
                    ),
            },

            /** 服务大厅 */
            //设备预约
            {
                path: "service/device",
                name: "device",
                component: () =>
                    import(
                        /* webpackChunkName: "device" */
                        "../views/service/Device.vue"
                    ),
            },
            //设备预约详情
            {
                path: "service/device/detail/:id",
                name: "device-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "device-detail" */
                        "../views/service/DeviceDetail.vue"
                    ),
            },
            //设备预约须知
            {
                path: "service/device/notice",
                name: "device-notice",
                component: () =>
                    import(
                        /* webpackChunkName: "device-notice" */
                        "../views/service/DeviceNotice.vue"
                    ),
            },
            //场地预约
            {
                path: "service/reservation",
                name: "reservation",
                component: () =>
                    import(
                        /* webpackChunkName: "reservation" */
                        "../views/service/Reservation.vue"
                    ),
            },
            //文档下载
            {
                path: "service/download",
                name: "download",
                component: () =>
                    import(
                        /* webpackChunkName: "download" */
                        "../views/service/DownLoad.vue"
                    ),
            },
            //我要发布
            {
                path: "service/publish",
                name: "publish",
                component: () =>
                    import(
                        /* webpackChunkName: "publish" */
                        "../views/service/Publish.vue"
                    ),
            },
            //我要发布
            {
                path: "service/publish/detail",
                name: "publish",
                component: () =>
                    import(
                        /* webpackChunkName: "PublishDetail" */
                        "../views/service/PublishDetail.vue"
                    ),
            },
            //办事指南
            {
                path: "service/guidence",
                name: "guidence",
                component: () =>
                    import(
                        /* webpackChunkName: "guidence" */
                        "../views/service/Guidence.vue"
                    ),
            },
            //办事指南详情
            {
                path: "service/guidence/detail/:id",
                name: "guidence-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "guidence-detail" */
                        "../views/service/GuidenceDetail.vue"
                    ),
                props: {
                    url: "/hall/guide",
                },
            },
            /** 协同创新 */
            {
                path: "innovation/ex-lab",
                name: "ex-lab",
                component: () =>
                    import(/*ex-lab*/ "../views/innovation/Exlab.vue"),
            },
            {
                path: "innovation/e-club",
                name: "e-club",
                component: () =>
                    import(/*e-club*/ "../views/innovation/Eclub.vue"),
            },
            {
                path: "innovation/e-club/:id",
                name: "e-club-detail",
                component: () =>
                    import(/*e-club*/ "../views/innovation/EclubDetail.vue"),
            },
            /** 成果展示 */
            //项目
            {
                path: "achievement/project",
                name: "project",
                component: () =>
                    import(
                        /* webpackChunkName: "project" */
                        "../views/achievement/Project.vue"
                    ),
            },
            //竞赛
            {
                path: "achievement/competition",
                name: "competition",
                component: () =>
                    import(
                        /* webpackChunkName: "competition" */
                        "../views/achievement/Project.vue"
                    ),
                props: {
                    type: "competition",
                },
            },
            //项目详情
            {
                path: "achievement/project/detail/:id",
                name: "project-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "project-detail" */
                        "../views/achievement/ProjectDetail.vue"
                    ),
            },
            //竞赛详情
            {
                path: "achievement/competition/detail/:id",
                name: "competition-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "competition-detail" */
                        "../views/achievement/ProjectDetail.vue"
                    ),
            },
            //论文
            {
                path: "achievement/treatise",
                name: "treatise",
                component: () =>
                    import(
                        /* webpackChunkName: "treatise" */
                        "../views/achievement/Patent.vue"
                    ),
                props: {
                    type: [1, 2, 4, 5],
                    sort: {
                        year: -1,
                        adminTime: -1,
                    },
                },
            },
            // //专利
            // {
            //   path: 'achievement/patent',
            //   name: 'patent',
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "patent" */
            //       '../views/achievement/Patent.vue'
            //     ),
            //   props: {
            //     type: [5],
            //     sort: {
            //       patentApplicationTime: -1,
            //       adminTime: -1,
            //     },
            //   },
            // },
            /** 校企合作 */
            //合作指南
            {
                path: "cooperation/guide",
                name: "guide",
                component: () =>
                    import(
                        /* webpackChunkName: "guide" */
                        "../views/cooperation/Guide.vue"
                    ),
            },

            //共建资讯
            {
                path: "cooperation/news",
                name: "news",
                component: () =>
                    import(
                        /* webpackChunkName: "news" */
                        "../views/cooperation/News.vue"
                    ),
            },
            //共建资讯详情
            {
                path: "cooperation/news/detail/:id",
                name: "news-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "news-detail" */
                        "../views/service/GuidenceDetail.vue"
                    ),
                props: {
                    url: "/cooperation/news",
                },
            },
            //申请入口
            {
                path: "cooperation/apply",
                name: "apply",
                component: () =>
                    import(
                        /* webpackChunkName: "apply" */
                        "../views/cooperation/Apply.vue"
                    ),
            },
            /** 新闻 */
            //新闻
            {
                path: "news",
                name: "news1",
                component: () =>
                    import(
                        /* webpackChunkName: "news" */
                        "../views/information/News.vue"
                    ),
            },
            //新闻详情
            {
                path: "news/detail/:id",
                name: "news-detail1",
                component: () =>
                    import(
                        /* webpackChunkName: "news-detail1" */
                        "../views/information/NewsDetail.vue"
                    ),
                props: {
                    url: "/information/news",
                },
            },
            // 党政动态
            {
                path: "govnews",
                name: "govnews",
                component: () =>
                    import(
                        /* webpackChunkName: "govnews" */
                        "../views/information/GovNews.vue"
                    ),
            },
            //党政动态详情
            {
                path: "govnews/detail/:id",
                name: "govnews-detail1",
                component: () =>
                    import(
                        /* webpackChunkName: "govnews-detail1" */
                        "../views/information/GovNewsDetail.vue"
                    ),
                props: {
                    url: "/govnews/news",
                },
            },
            //E-Talk轮播
            {
                path: "eTalk/carousel-index",
                name: "eTalk-index",
                component: () =>
                    import(
                        /* webpackChunkName: "competition-activities-carousel" */
                        "../compontents/information/EtalkCarousel.vue"
                    ),
            },
            /** E-Talk */
            {
                path: "eTalk",
                name: "eTalk",
                component: () =>
                    import(
                        /* webpackChunkName: "eTalk" */
                        "../views/information/E-Talk.vue"
                    ),
            },
            // E-Talk详情
            {
                path: "eTalk/detail/:id",
                name: "eTalk-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "eTalk-detail" */
                        "../views/information/ETalkDetail.vue"
                    ),
            },
            // E-Talk预约
            {
                path: "eTalk/detail/order/:id",
                name: "eTalk-order",
                component: () =>
                    import(
                        /* webpackChunkName: "eTalk-detail" */
                        "../views/information/ETalkOrder.vue"
                    ),
            },
            /** 科创课题 */
            {
                path: "info/project",
                name: "science",
                component: () =>
                    import(
                        /* webpackChunkName: "project" */
                        "../views/information/ScienceProject.vue"
                    ),
            },

            /** 特色课程 */
            {
                path: "courses",
                name: "courses",
                component: () =>
                    import(
                        /* webpackChunkName: "courses" */
                        "../views/information/SpecialCourse.vue"
                    ),
            },
            /** 特色课程详情 */
            {
                path: "courses/detail/:id",
                name: "courses-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "courses-detail" */ "../views/information/SpecialDetail.vue"
                    ),
            },
            /** 科创课题详情 */
            {
                path: "info/project/detail/:id",
                name: "science-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "science-detail" */ "../views/information/ScienceDetail.vue"
                    ),
            },
            /** 科创课题轮播 */
            {
                path: "info/project/carousel-index",
                name: "project-index",
                component: () =>
                    import("../compontents/information/CourseCarousel.vue"),
            },
            {
                path: "ted-x",
                name: "Ted-x",
                component: () => import("../views/information/TedX.vue"),
            },
        ],
    },
    {
        path: "/common",
        name: "common",
        component: () =>
            import(/* webpackChunkName: "common" */ "../views/OtherBase.vue"),
        children: [
            //登录
            {
                path: "login",
                name: "login",
                component: () =>
                    import(
                        /* webpackChunkName: "login" */ "../views/form/Login.vue"
                    ),
            },
            //注册
            {
                path: "register",
                name: "register",
                component: () =>
                    import(
                        /* webpackChunkName: "register" */
                        "../views/form/Register.vue"
                    ),
            },
            //搜索
            {
                path: "search",
                name: "search",
                component: () =>
                    import(
                        /* webpackChunkName: "search" */ "../views/form/Search.vue"
                    ),
            },
            //基本设置
            {
                path: "setting",
                name: "setting",
                component: () =>
                    import(
                        /* webpackChunkName: "setting" */ "../views/form/Setting.vue"
                    ),
            },
            //消息通知
            {
                path: "notice",
                name: "notice",
                component: () =>
                    import(
                        /* webpackChunkName: "notice" */ "../views/form/Notice.vue"
                    ),
            },
            {
                path: "reserve/device",
                name: "reserveDevice",
                component: () =>
                    import(
                        /* webpackChunkName: "notice" */ "../views/form/reserveDevice.vue"
                    ),
            },
            {
                path: "reserve/service",
                name: "reserveService",
                component: () =>
                    import(
                        /* webpackChunkName: "notice" */ "../views/form/reserveService.vue"
                    ),
            },
        ],
    },
    {
        path: "/test",
        name: "test",
        component: () =>
            import(
                /* webpackChunkName: "test" */
                "../views/service/Reservation.vue"
            ),
    },
    {
        path: "*",
        name: "error",
        props: {
            show: false,
        },
        component: () =>
            import(/* webpackChunkName: "error" */ "../compontents/Error.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    ...cond,
    // 下面这个不知道为什么不生效
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
    routes,
});
// 手动滚动到顶部
router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    next();
});

export default router;
